<template>
  <div>
    <b-row>
      <b-col cols="4">
        <h6 class="text-primary">
          Número de Ticket
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            class="text-center"
            :value="datosItem.ticket"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            class="text-center"
            :value="datosItem.estado"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <h6 class="text-primary">
          Fecha de Despacho
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            class="text-center"
            :value="formatoFecha(datosItem.fechaCreacion)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <h6 class="text-primary">
          Persona que recibio
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            class="text-center"
            :value="datosItem.nombreReceptor"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <h6 class="text-primary">
          DPI
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            class="text-center"
            :value="datosItem.datosDespacho.dpi"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Comentario de despacho
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="datosItem.datosDespacho.comentario"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr>
        <h6 class="text-primary">
          Listado de Items Despachados
        </h6>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="datosItem.items"
          :fields="camposTabla"
          responsive
          fixed
          small
          striped
        >
          <template v-slot:cell(nombre)="{ item }">
            <h5 class="align-text-top text-uppercase">
              {{ item.nombre }}
            </h5>
          </template>
          <!--template v-slot:cell(check)="{ item }">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              @click="mostrarDetalle(item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template-->
        </b-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="verDetalleItemsDespachados"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-row>
          <b-col
            v-for="(item, index) in datosDespacho"
            :key="index"
            cols="6"
          >
            <b-card>
              <b-row>
                <b-col cols="6">
                  <h6 class="text-primary">
                    POTENCIA
                  </h6>
                  <b-form-group>
                    <b-form-input
                      id="disabledInput"
                      class="text-center"
                      :value="item.potencia"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <h6 class="text-primary">
                    SERIE
                  </h6>
                  <b-form-group>
                    <b-form-input
                      id="disabledInput"
                      class="text-center"
                      :value="item.serie"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <h6 class="text-primary">
                    TIPO
                  </h6>
                  <b-form-group>
                    <b-form-input
                      id="disabledInput"
                      class="text-center"
                      :value="item.tipo"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <h6 class="text-primary">
                    POTENCIA
                  </h6>
                  <b-form-group>
                    <b-form-input
                      id="disabledInput"
                      class="text-center"
                      :value="item.potencia"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <h6 class="text-primary">
                    TIPO LÁMPARA
                  </h6>
                  <b-form-group>
                    <b-form-input
                      id="disabledInput"
                      class="text-center"
                      :value="item.tipoLampara"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BFormGroup, BFormTextarea, BTable, BButton, VBModal,
} from 'bootstrap-vue'
import { formatDate, formatoFecha } from '@/utils/fechas'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    datosItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datosDespacho: [],
      verDetalleItemsDespachados: false,
      camposTabla: [
        { key: 'potencia', label: 'Potencia' },
        { key: 'serie', label: 'Serie' },
        { key: 'tipo', label: 'Tipo' },
        { key: 'tipoLampara', label: 'Tipo Lámpara' },
        { key: 'grupo', label: 'Grupo' },
        { key: 'mejoraRealizada', label: 'Destino' },
      ],
      datosItemDespacho: [
        {
          nombre: 'Items Despachados',
        },
      ],
    }
  },
  methods: {
    mostrarDetalle(itemsDespachados) {
      this.datosDespacho = this.datosItem.items
      this.verDetalleItemsDespachados = true
    },
    formatoFecha,
    formatDate,
  },
}
</script>
<style scoped>

</style>
