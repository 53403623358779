<template>
  <div>
    <b-overlay
      opacity="0.80"
      variant="transparent"
      blur="0.40rem"
      :show="show"
    >
      <template #overlay>
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="7" />
            <b-col
              v-if="!isMobile"
              cols="2"
            >
              <b-form-group>
                <label>Fecha Inicio:</label>
                <b-input-group class="mb-1">
                  <b-form-input
                    id="example-input"
                    v-model="fechaInicio"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="fechaInicio"
                      show-decade-nav
                      button-only
                      size="sm"
                      right
                      locale="en-US"
                      aria-controls="example-input"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isMobile"
              cols="2"
            >
              <b-form-group>
                <label>Fecha Fin:</label>
                <b-input-group class="mb-1">
                  <b-form-input
                    id="fecha-fin"
                    v-model="fechaFin"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="fechaFin"
                      show-decade-nav
                      button-only
                      size="sm"
                      right
                      locale="en-US"
                      aria-controls="example-input"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              style="margin-top: 23px"
              cols="1"
            >
              <table style="margin-left: -20px">
                <tr>
                  <td>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      title="Buscar"
                      variant="primary"
                      class="btn-icon"
                      @click="loadData"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </td>
                  <td>
                    <b-overlay :show="showLoading">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.v-primary
                        title="Descargar PDF"
                        variant="primary"
                        class="btn-icon"
                        @click="generarPdfDespachos"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </b-overlay>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <vue-good-table
                :columns="columnas"
                :rows="datosTabla"
                style-class="vgt-table condensed"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength
                }"
                @on-row-dblclick="showDetalle"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'fechaCreacion'">
                    {{ calcularFecha(props.row.fechaCreacion) }}
                  </span>
                  <span v-else-if="props.column.field === 'itemsDespachados'">
                    {{ contarItemsDespachados(props.row) }}
                  </span>
                </template>
                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        Mostrar
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '15', '20']"
                        class="mx-1"
                        @input="value => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> de {{ props.total }} registros </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="value => props.pageChanged({ currentPage: value })"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
    <div>
      <b-modal
        v-model="verDialogoDetalle"
        title="Detalle de Despacho"
        size="lg"
        ok-only
        ok-title="Aceptar"
        no-close-on-backdrop
      >
        <detalle-modulo-despachos :datos-item="datosItem" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BPagination,
  BOverlay,
  BFormDatepicker,
  BInputGroupAppend,
  BFormInput, BFormGroup, BInputGroup, VBTooltip, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { notificacionError } from '@/utils/mensajes'
import { findDespachos } from '@/utils/despachos/despachos'
import { isMobile } from '@/utils/funciones'
import Ripple from 'vue-ripple-directive'
import DetalleModuloDespachos from '@/components/despachos/detalle-modulo-despachos.vue'
import { generarReporteDespachosBodega } from '@/utils/reportes/Pdfs/despachosApvn/reporteDepachos'
import { calcularFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'

export default {
  components: {
    DetalleModuloDespachos,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BOverlay,
    BPagination,
    BFormInput,
    BFormGroup,
    BInputGroup,
    VueGoodTable,
    BFormDatepicker,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    columnas: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showLoading: false,
      datosItem: '',
      fechaInicio: '',
      pageLength: 15,
      fechaFin: '',
      datosTabla: [],
      verDialogoDetalle: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
  },
  async created() {
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.show = true
        const fechaInicio = new Date(this.fechaInicio)
        const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { fechaCreacion: { gte: fechaInicio.toISOString() } },
              { fechaCreacion: { lte: fechaFin.toISOString() } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }
        this.datosTabla = await findDespachos(filter)
      } catch (error) {
        console.error(error)
        notificacionError('Algo ha salido mal :(', 'Intentalo nuevamente.')
      } finally {
        this.show = false
      }
    },
    async generarPdfDespachos() {
      try {
        this.showLoading = true
        // SE UTILIZA FLATMAP PORQUE SI NO SE HACE ESTO, AL INTENTAR GENERAR EL REPORTE DICE QUE ES UNDEFINED this.datosTabla.items.
        const itemsDespachos = this.datosTabla.flatMap(dato => dato.items)
        const fileResumen = await generarReporteDespachosBodega(itemsDespachos, this.usuario)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Despachos.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.showLoading = false
      }
    },
    async showDetalle(params) {
      const detalleDespacho = this.datosTabla.find(row => row.id === params.row.id)
      if (detalleDespacho) {
        this.datosItem = detalleDespacho
        this.verDialogoDetalle = true
      }
    },
    contarItemsDespachados(row) {
      return row.items ? row.items.length : 0
    },
    calcularFecha,
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
