<template>
  <div>
    <componente-despachos
      :columnas="columnas"
    />
  </div>
</template>
<script>
import ComponenteDespachos from '@/components/bodega/componente-despachos.vue'

export default {
  components: {
    ComponenteDespachos,
  },
  data() {
    return {
      columnas: [
        {
          label: 'No. Ticket',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Persona que Recibio',
          field: 'nombreReceptor',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Cantidad de Items Despachados',
          field: 'itemsDespachados',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
